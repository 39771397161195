import React from 'react';

const JumboSvg = (props) => {

    return (
        <svg className={props.classes} xmlns="http://www.w3.org/2000/svg" width="780.438" height="755.53" viewBox="0 0 780.438 755.53">
            <defs>
                
                <filter id="Path_3" x="43.508" y="16.532" width="686.956" height="671.092" filterUnits="userSpaceOnUse">
                <feOffset dy="10" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="25" result="blur-2"/>
                <feFlood floodOpacity="0.133"/>
                <feComposite operator="in" in2="blur-2"/>
                <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_25" data-name="Group 25" transform="translate(-890.54 -65.966)">
                <g transform="matrix(1, 0, 0, 1, 890.54, 65.97)">
                <path id="Path_4-2" data-name="Path 4" d="M567.709,654.792C529.882,787.244,726.249,908.051,869.8,908.051s240.345-15.07,300.562-95.138-11.126-101.141-59.693-225.136-8.056-198.928-90.737-237.76-169.208-83.928-251.76,0S605.535,522.34,567.709,654.792Z" transform="translate(-487.96 -237.52)" fill="#e4eef7"/>
                </g>
                <g transform="matrix(1, 0, 0, 1, 890.54, 65.97)" filter="url(#Path_3)">
                <path id="Path_3-2" data-name="Path 3" d="M2.1,274.841C-19.166,381.906,125.788,472.434,237.783,472.434s187.517-11.757,234.5-74.227-8.68-78.91-46.572-175.651-6.285-155.2-70.793-185.5-132.016-65.481-196.423,0S23.356,167.777,2.1,274.841Z" transform="matrix(0.99, 0.1, -0.1, 0.99, 167.89, 81.53)" fill="#bdc2d3"/>
                </g>
                <g id="rocket" data-name="Group 22">
                <g id="flame_1" data-name="Group 24" transform="translate(1312.01 694.127) rotate(180)">
                    <path id="Path_17" data-name="Path 17" d="M31.025,186.527C10.2,186.527,0,151.345,0,134.037,0,114.489,27.182,31.278,27.182,0,34.409,40.773,62.8,100.354,64.16,140.734,65.027,166.421,48.45,186.527,31.025,186.527Z" transform="translate(0 0)" fill="#f15a24"/>
                    <path id="Path_18" data-name="Path 18" d="M26.42,148.584C7.964,148.115-.345,119.86.011,106.077.414,90.517,26.256,24.9,26.893,0c5.558,32.623,29.526,80.717,29.854,112.891C56.972,133.362,41.862,148.987,26.42,148.584Z" transform="translate(2.037 34.554)" fill="#c1272d" opacity="0.4"/>
                    <path id="Path_19" data-name="Path 19" d="M19.171,113.083C5.252,112.033-.513,90.231.036,79.752.631,67.923,21.35,18.929,22.306,0c3.585,25.036,20.757,62.529,20.419,87.035C42.515,102.637,30.8,113.969,19.171,113.083Z" transform="translate(15.599 66.414)" fill="#f7931e" opacity="0.4"/>
                    <path id="Path_20" data-name="Path 20" d="M25.237,119.565c15.831,0,23.583-22.552,23.583-33.65C48.82,73.388,28.143,20.045,28.143,0,22.65,26.133,1.055,64.328.024,90.212-.619,106.677,11.988,119.565,25.237,119.565Z" transform="translate(9.054 62.801)" fill="#f7931e" opacity="0.4"/>
                    <path id="Path_21" data-name="Path 21" d="M20.624,106.02C7.234,106.461.237,86.669,0,76.818-.241,65.7,16.2,17.767,15.806,0c5.155,23.035,24.174,56.328,25.542,79.265C42.229,93.863,31.825,105.65,20.624,106.02Z" transform="translate(6.83 73.655)" fill="#f7931e" opacity="0.4"/>
                </g>
                <g id="flame_2" data-name="Group 2" transform="translate(1312.01 694.127) rotate(180)">
                    <path id="Path_17-2" data-name="Path 17" d="M31.025,186.527C10.2,186.527,0,151.345,0,134.037,0,114.489,33.066,70.83,33.066,39.552c7.227,40.773,29.734,60.8,31.093,101.182C65.027,166.421,48.45,186.527,31.025,186.527Z" transform="translate(0 0)" fill="#f15a24"/>
                    <path id="Path_18-2" data-name="Path 18" d="M26.42,148.584C7.964,148.115-.345,119.86.011,106.077c.4-15.56,30.056-61.153,30.693-86.053,5.558,32.623,25.715,60.693,26.043,92.867C56.972,133.362,41.862,148.987,26.42,148.584Z" transform="translate(2.037 34.554)" fill="#c1272d" opacity="0.4"/>
                    <path id="Path_19-2" data-name="Path 19" d="M19.171,113.083C5.252,112.033-.513,90.231.036,79.752c.6-11.829,18.179-40.1,19.135-59.025,3.585,25.036,23.892,41.8,23.555,66.308C42.515,102.637,30.8,113.969,19.171,113.083Z" transform="translate(15.599 66.414)" fill="#f7931e" opacity="0.4"/>
                    <path id="Path_20-2" data-name="Path 20" d="M25.237,119.565c15.831,0,23.583-22.552,23.583-33.65,0-12.527-23.583-59.462-23.583-79.506C19.745,32.541,1.055,64.328.024,90.212-.619,106.677,11.988,119.565,25.237,119.565Z" transform="translate(9.054 62.801)" fill="#f7931e" opacity="0.4"/>
                    <path id="Path_21-2" data-name="Path 21" d="M18.1,106.02C6.349,106.461.208,86.669,0,76.818-.211,65.7,20.559,36.607,20.214,18.841,24.739,41.875,35.089,56.328,36.29,79.265,37.064,93.863,27.932,105.65,18.1,106.02Z" transform="translate(11.894 73.655)" fill="#f7931e" opacity="0.4"/>
                </g>
                <g id="Group_23" data-name="Group 23">
                    <path id="Path_11" data-name="Path 11" d="M215.542,629.685c-.051-2.027.1-4.053.148-6.08.114-4.989.285-9.984.434-14.974.005-.078-.014-8.935,0-9.013,1.374-.011,38.738-1.491,39.452-1.879a35.544,35.544,0,0,1-1,3.682q-2.084,7.147-4.2,14.271c-.639,2.169-1.3,4.327-1.952,6.491-.188-.234-.394-.057-.571,0-.268.086-.514.257-.782.36-2.752,1.09-5.5,2.2-8.34,3.043-1.393.417-2.78.856-4.179,1.244-2.249.622-4.527,1.142-6.822,1.536-.942.171-1.884.377-2.854.485-1.518.171-3.026.394-4.567.519a43.636,43.636,0,0,1-4.767.314Z" transform="translate(1069.86 -109.302)" fill="#6b91af"/>
                    <path id="Path_12" data-name="Path 12" d="M215.194,648a43.641,43.641,0,0,0,4.812-.274c1.524-.126,3.037-.342,4.567-.519.948-.108,1.89-.308,2.854-.485,2.283-.422,4.567-.913,6.822-1.536,1.4-.388,2.786-.833,4.179-1.245,2.854-.845,5.589-1.952,8.34-3.043.268-.1.508-.274.782-.36.171-.057.382-.228.571,0-.44,1.633-.873,3.265-1.33,4.892-.959,3.425-1.941,6.8-2.889,10.207a1.039,1.039,0,0,1-.571.748,77.714,77.714,0,0,1-16.463,5.9,66.243,66.243,0,0,1-8.14,1.393c-1.142.12-2.323.263-3.494.3a.862.862,0,0,0-.514.154l.285-9.739C215.057,652.263,215.131,650.134,215.194,648Z" transform="translate(1070.209 -127.621)" fill="#4c687d"/>
                    <path id="Path_13" data-name="Path 13" d="M168.413,598.38c.042,2.082.152,14.283.194,16.361q.137,7.141.274,14.271v.776a3.154,3.154,0,0,1-.468,0c-1.553-.251-3.128-.377-4.687-.571-2.232-.308-4.436-.742-6.628-1.233a80.185,80.185,0,0,1-9.842-2.888,75.91,75.91,0,0,1-9.4-4.156,4.35,4.35,0,0,0-1.313-.662c-.371-1.593-.754-3.185-1.107-4.784-.765-3.425-1.513-6.9-2.283-10.355-.394-1.8-.8-3.6-1.187-5.406-.1-.451-.4-.856-.291-1.353.234.027,36.065.018,36.74,0Z" transform="translate(1105.858 -109.577)" fill="#6b91af"/>
                    <path id="Path_14" data-name="Path 14" d="M140.16,636.72a4.35,4.35,0,0,1,1.313.662,75.883,75.883,0,0,0,9.4,4.156,80.208,80.208,0,0,0,9.842,2.889c2.192.491,4.4.919,6.628,1.233,1.558.217,3.134.337,4.687.571a3.154,3.154,0,0,0,.468,0c.057,2.106.12,4.207.166,6.314.063,3.043.114,6.08.171,9.134v.571a68.629,68.629,0,0,1-8.317-1.267,69.573,69.573,0,0,1-15.984-5.514,44.031,44.031,0,0,1-4.567-2.546,1.056,1.056,0,0,1-.571-.7Q141.781,644.461,140.16,636.72Z" transform="translate(1102.206 -126.03)" fill="#4c687d"/>
                    <g id="Rectangle_3" data-name="Rectangle 3" transform="translate(1188.589 132.305)" fill="#fff" stroke="#4c687d" strokeWidth="6">
                    <rect width="185.032" height="359.448" rx="31" stroke="none"/>
                    <rect x="3" y="3" width="179.032" height="353.448" rx="28" fill="none"/>
                    </g>
                    <path id="Rectangle_4" data-name="Rectangle 4" d="M0,0H82.658a0,0,0,0,1,0,0V5.442a12,12,0,0,1-12,12H12a12,12,0,0,1-12-12V0A0,0,0,0,1,0,0Z" transform="translate(1240.156 134.58)" fill="#4c687d"/>
                    <path id="Path_5" data-name="Path 5" d="M82.485,518.993a18.417,18.417,0,0,0-6.719,2.164,26.333,26.333,0,0,0-8.078,6.919,50.719,50.719,0,0,0-6.742,11.417,103.839,103.839,0,0,0-5.783,17.468,182.054,182.054,0,0,0-3.425,18.1q-.913,6.462-1.479,12.97c-.571,6.753-.993,13.518-1.062,20.294a2.914,2.914,0,0,0,0,.314c.029.3-.091.428-.4.411s-.628,0-.936,0H42.291c-.611,0-.617,0-.736-.622q-1.039-5.446-2.072-10.9-1.61-8.42-3.22-16.817t-3.191-16.726q-1.6-8.4-3.191-16.8L26.832,531.2,23.8,515.323q-.283-1.456-.571-2.911c-.051-.28-.16-1.336-.16-1.6,0-.622.1-1.176.1-1.867a76.564,76.564,0,0,1,9.7-30.969,114.115,114.115,0,0,1,15.562-21.521A142.436,142.436,0,0,1,69.84,437.081c.1-.074.217-.143.325-.211a3.015,3.015,0,0,1,.143.925c.28,3.425-2.94,58.585-2.62,62.01.381,4.152,14.886,18.914,14.8,19.189Z" transform="translate(1121.899 -31.778)" fill="#4c687d"/>
                    <path id="Path_7" data-name="Path 7" d="M199.32,771.7v-.194h3.591c1.039,0,2.078-.057,3.117.04v.16Z" transform="translate(1101.432 -436.8)" fill="#d8d8d8"/>
                    <path id="Path_9" data-name="Path 9" d="M290.39,518.384c1.633-7.769,10.247-8.691,10.681-13.1.405-4.1,1.833-65.349,2.141-69.46a.445.445,0,0,1,.148-.417,140.936,140.936,0,0,1,27.658,26.83A99.059,99.059,0,0,1,344.89,485.8a74.873,74.873,0,0,1,4.567,16.2c.291,1.775.479,3.562.645,5.355a20.1,20.1,0,0,1-.485,5.737c-1.108,5.646-2.175,11.3-3.254,16.954l-3.488,18.267q-1.713,8.86-3.391,17.7-1.844,9.63-3.693,19.255-1.6,8.4-3.2,16.806c-.308,1.61-.639,3.22-.919,4.835-.074.44-.263.531-.668.531h-6.337c-.605,0-.622,0-.622-.571,0-3.522-.177-7.039-.377-10.549q-.32-5.463-.862-10.915c-.325-3.265-.7-6.531-1.142-9.779-1.53-10.846-3.671-21.561-7.507-31.865a62.5,62.5,0,0,0-6.6-13.244,32.972,32.972,0,0,0-6.479-7.273,20.968,20.968,0,0,0-10.332-4.715A1.668,1.668,0,0,1,290.39,518.384Z" transform="translate(1068.291 -30.303)" fill="#4c687d"/>
                    <path id="Path_10" data-name="Path 10" d="M195.072,507.222q-.091-3.747-.188-7.493l-.251-10.2q-.194-7.646-.4-15.264c-.086-3.545-.131-7.091-.234-10.64-.1-3.268-.257-6.539-.36-9.807a65.11,65.11,0,0,1,.206-7.385,33.663,33.663,0,0,1,.833-5.421,14.14,14.14,0,0,1,1.713-4.282,6.715,6.715,0,0,1,1.524-1.722,5.553,5.553,0,0,1,6.656-.02,6.692,6.692,0,0,1,1.85,2.282,14.512,14.512,0,0,1,1.393,4.395,52.759,52.759,0,0,1,.679,8.523c0,2.817-.137,5.658-.183,8.487-.063,3.875-.1,7.755-.228,11.63q-.308,9.175-.651,18.351-.211,5.839-.44,11.67c-.08,2.093-.177,4.189-.257,6.282v.716a1.112,1.112,0,0,0-.029.165c-.148,3.517-.314,7.034-.434,10.556-.046,1.429-.2,2.857-.148,4.286-.068,1.5-.143,3-.206,4.5q-.148,3.433-.285,6.865-.188,4.326-.371,8.652l-.628,14.326q-.285,6.254-.6,12.507c0,.241.114.547-.451.583-1.039-.068-2.078-.024-3.117-.028h-3.591c-.086-2.278-.183-4.556-.257-6.841q-.234-7.646-.44-15.268-.2-6.89-.405-13.779a1.665,1.665,0,0,0-.034-.4,1.582,1.582,0,0,1,0-.4c-.057-2.145-.108-4.286-.171-6.439-.046-1.485-.108-2.966-.166-4.451a3.5,3.5,0,0,0,0-.547q-.137-5.034-.274-10.061Q195.135,509.423,195.072,507.222Z" transform="translate(1079.268 17.882)" fill="#4c687d"/>
                    <rect id="Rectangle_5" data-name="Rectangle 5" width="91" height="21" transform="translate(1210 179)" fill="#c6b6e3"/>
                    <rect id="Rectangle_6" data-name="Rectangle 6" width="144" height="21" transform="translate(1210 213)" fill="#c6b6e3"/>
                    <rect id="Rectangle_11" data-name="Rectangle 11" width="144" height="10" transform="translate(1210 381)" fill="#c6b6e3"/>
                    <rect id="Rectangle_12" data-name="Rectangle 12" width="126" height="10" transform="translate(1210 396)" fill="#c6b6e3"/>
                    <rect id="Rectangle_13" data-name="Rectangle 13" width="134" height="10" transform="translate(1210 411)" fill="#c6b6e3"/>
                    <rect id="Rectangle_10" data-name="Rectangle 10" width="144" height="68" transform="translate(1210 247)" fill="#c6b6e3"/>
                    <rect id="Rectangle_8" data-name="Rectangle 8" width="66" height="40" transform="translate(1288 328)" fill="#f0b1ff"/>
                    <rect id="Rectangle_9" data-name="Rectangle 9" width="67" height="40" transform="translate(1210 328)" fill="#49007e"/>
                </g>
                </g>
            </g>
        </svg>
    )
}

export default JumboSvg;